
import { defineComponent, ref,computed } from "@vue/runtime-core";
import moment from 'moment';
export default defineComponent({
    props:['dato'],
    setup(props){
        
        const es = moment(props.dato.datos.fecha).locale('es')
        const medico = ref(props.dato.medico.nombre);
        const especialidad = ref(props.dato.especialidad.nombre);
        const fecha = ref(es.format('dddd DD \\d\\e MMM \\d\\e\\l YYYY'))
        const isDescargable = computed(()=>{
            return moment().diff(es,'days') <= 30
        })
        const fechaVencimiento = computed(()=>{
            return es.add(30,'days').format('DD/MM/YYYY')
        })

        return {
            medico,
            especialidad,
            fecha,
            fechaVencimiento,
            isDescargable,
        }
    }

})
