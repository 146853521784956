


//Utils
import moment from 'moment'
import { useToast } from "primevue/usetoast";

//Table
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

//Side
import Sidebar from 'primevue/sidebar';


//Componentes
import ProximosTurnos from '@/components/ProximosTurnos.vue'


import axios from '@/plugins/axios';


import { defineComponent ,onMounted,ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router'


const encodeURIComponente = (objeto:any) => {
    return encodeURIComponent(JSON.stringify(objeto))
}

export default defineComponent({
    components:{
        DataTable,
        Column,
        Sidebar,
        ProximosTurnos,
    },
    setup(){
        
        const store = useStore();
        const toast = useToast();
        const route = useRoute();

        const datosHistorico = ref()
        const datosTurnos = ref()
        const slideVisible = ref(false);

        const handleHistorialClick = (bool:boolean) => {
            slideVisible.value = bool
        }


        const obtenerTurnosPacienteHistorico = async () => {
            const filters = {
                    idcliente:store.state.configuracionPortal.idcliente,
                    idpaciente:store.state.pacienteLogueado.id,
                    fecha:{ 'lte':moment()},  
            }

            


            try {
             
                const { data } = await axios.get(`turnos?filters=${encodeURIComponente(filters)}`)
                

                const hora = moment().format('HH:mm:ss')
                const fecha = moment().format('YYYY-MM-DD');

                console.log(data.turnos)

                datosHistorico.value = data.turnos
                .filter((turno:any) => {
                    if(turno.fecha === fecha){
                        
                        return turno.hora <= hora;
                    }
                    return true;
                })
                .map((turno:any) => ({
                    id:turno.id, 
                    fecha:moment(turno.fecha,'YYYY-MM-DD').format('DD/MM/YYYY'),
                    hora:moment(turno.hora,'HH:mm:ss').format('HH:mm'),
                    especialidad:turno.medicoEspecialidad?.servicioEspecialidad?.descripcion || turno.servicioEspecialidad?.descripcion || '-',
                    profesional:turno.medicoEspecialidad?.medico?.nombre || '-',
                    sucursal:`${turno.medicoEspecialidad?.sucursal?.direccion} - ${turno.medicoEspecialidad?.sucursal?.direccion}`,
                    vino:turno.presente ? 'Si' : 'No'
                }))

                



            } catch (error) {
                console.log(error);
            }
        }


        const obtenerTurnosPaciente = async () => {
                try {

                    const filters = {
                        idcliente:store.state.configuracionPortal.idcliente,
                        idpaciente:store.state.pacienteLogueado.id,
                        fecha:{ '>=':moment()},
                        idmedicosespecialidad:{ne:null}
                    }

                    const fecha = moment().format('YYYY-MM-DD');
                    const hora = moment().subtract(30, 'minutes').format('HH:mm:ss');

                    const includes = [
                        {model:'turnoVideoconsultas'},
                        {model:'turnoPrestacion',include:[{model:'prestacion',include:[{model:'instruccionPrestacion'}]}]}
                    ]
                    const { data } = await axios.get(`turnos?filters=${encodeURIComponente(filters)}&includes=${encodeURIComponente(includes)}`)
                    

                    datosTurnos.value = data.turnos.filter((turno:any) => {
                        if(turno.fecha === fecha){
                            return turno.hora >= hora;
                        }
                        return true;
                    })
                    .sort((a:any,b:any) => {
                        if(a.fecha === b.fecha){
                            return a.hora > b.hora ? 1 : -1
                        }
                        return a.fecha > b.fecha ? 1 : -1
                    })

                    const { q } = route.query;

                    if(q){
                        
                        const index = datosTurnos.value.findIndex((turno:any)=> `${turno.id}` === q );
                        if(index !== -1){
                            const turno = datosTurnos.value[index];
                            datosTurnos.value.splice(index,1);
                            datosTurnos.value.splice(0,0,turno);
                        }



                    }

                    
                    
                } catch (error) {
                    console.log(error);
                }
        }

        const handleTurnoEliminado = () => {
            toast.add({severity:'success',summary:'Exito',detail:'Cita eliminada.',life:1500})
            obtenerTurnosPaciente();
        }



        onMounted(() => {
            
            obtenerTurnosPacienteHistorico()
            obtenerTurnosPaciente()
        })
        
        return { 
            datosHistorico,
            datosTurnos,
            slideVisible,
            handleHistorialClick,
            handleTurnoEliminado }
    }
});
