

import {defineComponent, ref, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import {jsPDF} from 'jspdf'

import axios from '@/plugins/axios'

import { useConfirm } from "primevue/useconfirm"
import useLogo from '@/hooks/useLogo';
import { imprimirTurno } from '@/hooks/useImpresion';

export default defineComponent({
    props:['turno'],
    setup(props,{emit}){
        
        const dialog = useConfirm();
        const store = useStore();
        const logoClinica = useLogo();

        const { turno } = toRefs(props);
        const urlBase = ref('')

        const es = moment(`${turno.value.fecha} ${turno.value.hora}`, 'YYYY-MM-DD HH:mm:ss').locale('es')
        
        const fecha = es.format('dddd DD \\d\\e MMM \\d\\e\\l YYYY - HH:mm')
        
        const logo = turno.value?.medicoEspecialidad?.medico?.logo || logoClinica
        
        const isCancelable = computed(()=> {
            const fechaTurno = turno.value.fecha;
            const fecha = moment().format('YYYY-MM-DD');

            return !(fecha === fechaTurno)


        })

        const handleCancelarTurno = async () => {
            ///delete => turnos/:id
            try {
                await axios.delete(`turnos/${turno.value.id}`)
                
                enviarMail(turno.value);

                emit('turnoEliminado')

            } catch (error) {
                console.log(error);
            }


        }

        const handleConfirmarCancelacion = () => {
            
        
                dialog.require({
                    message:`${fecha} para el profesional ${turno.value.medicoEspecialidad.medico.nombre}.`,
                    header:'Eliminar Turno',
                    icon: 'pi pi-exclamation-triangle',
                    rejectLabel:'Si',
                    acceptLabel:'No',
                    acceptClass:'p-button-link',
                    rejectClass:'p-button-primary',
                    reject: handleCancelarTurno,
                })
        
        
        
        }

        const enviarMail = async (turno:any) => {
            try {
                 const titulo = 'TURNO CANCELADO';
                
                const { nombre ,email } = store.state.pacienteLogueado;
                const { cliente } = store.state.configuracionPortal
                
                const fecha = moment(`${turno.fecha} ${turno.hora}`,'YYYY-MM-DD HH:mm:ss');
                
                const medico = turno.medicoEspecialidad.medico.nombre;
                const especialidad = turno.medicoEspecialidad.servicioEspecialidad.descripcion;
                const sucursal = `${turno.medicoEspecialidad.sucursal.sucursal} - ${turno.medicoEspecialidad.sucursal.direccion}`

                let fechaIcs = fecha.toISOString();
                fechaIcs = fechaIcs.replaceAll('-','').replaceAll(':','').replaceAll('.','');


                await axios.post('/send-email/send', {
                    toEmails:[email],
                    template_id:'d-50d122b4f0124e428c4f1ffdde3f20c1',
                    dynamic_template_data:{
                        logo:cliente.descripcion,
                        titulo:titulo,
                        nombre:`${nombre}`,
                        medico:`${medico}`,
                        especialidad:`${especialidad}`,
                        fecha:`${fecha.format('DD/MM/YYYY')}`,
                        hora:`${moment(turno.hora,'HH:mm:ss').format('HH:mm')}`,
                        direccion:`${sucursal}`

                    },
                    atachment:{
                        type:'ics',
                        data:`BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//hacksw/handcal//NONSGML v1.0//EN\nBEGIN:VEVENT\nSUMMARY:Turno con Profesional ${medico}\nLOCATION:${sucursal}\nUID:20210210203529.594${email}\nDESCRIPTION:\nDTSTART:${fechaIcs}\nDTEND:${fechaIcs}\nEND:VEVENT\nEND:VCALENDAR\n`
                    }
                });
             



            } catch (error) {
                console.log(error);
            }
        }

        const handleImprimirTurno = async () => {
            const jspdf = new jsPDF('p','pt','a4');
            imprimirTurno(turno.value,jspdf, logoClinica);

            jspdf.save(`${turno.value.medicoEspecialidad.medico.nombre} - ${turno.value.paciente.nombre} - ${turno.value.fecha} - ${turno.value.hora}.pdf`);
        }
        
        const getUrlBase = () => {
            const host = window.location.host 
            const subdomain = host.split('.')[0]
            if(subdomain === 'testing-portal'){
                return 'https://testing.notifications.globalapp.ar'
            } else if(subdomain === 'portal') {
                return 'https://notifications.globalapp.ar'
            } else {
                return 'http://localhost:4000/views'
            }
        }

        urlBase.value = getUrlBase()

        const salaVideoConsulta = computed( () => {
            if(turno.value && turno.value.turnoVideoConsulta){
                if(turno.value.presente === false)
                    return null
                if(moment().format('YYYY-MM-DD') === turno.value.fecha) {

                    if(moment().isBefore( moment(turno.value.hora, 'HH:mm:ss').subtract(30, 'minutes') ))
                        return null

                    if(moment().isAfter( moment(turno.value.hora, 'HH:mm:ss').add(30, 'minutes') ))
                        return null

                    return `${urlBase.value}/room/${turno.value.turnoVideoConsulta.idsala}`
                }
            }
            return null
        }) 

        return {
            fecha, 
            logo,
            urlBase,
            isCancelable, 
            salaVideoConsulta,
            handleCancelarTurno, 
            handleConfirmarCancelacion,
            handleImprimirTurno
        }
    }
})
