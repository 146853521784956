
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex';

import moment from 'moment';

export default defineComponent({
    props:['datos'],
    setup(props){
        const store = useStore()
        const es = moment(props.datos.datos.fecha).locale('es')
        const fecha = ref(es.format('dddd DD \\d\\e MMM \\d\\e\\l YYYY'))
        

        const isPac = computed(() => {
            return props.datos.tipo === 'PAC'
        })


        const verImagenesEstudios = computed(() => store?.state?.configuracionPortal?.ver_imagenes_estudios)
        
        return {
            fecha,
            isPac,
            verImagenesEstudios,
        }
    }
})
