import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue';
import Home from '../views/Home.vue';
import store from '@/store';

import MisTurnos from '../views/MisTurnos.vue';
import NuevoTurno from '@/views/NuevoTurno.vue';
import MisDatos from '@/views/MisDatos.vue';
import Resultados from '@/views/Resultados.vue';
import Recetas from '@/views/Recetas.vue';
import NotFoundComp from '@/views/404.vue';
import LoadingPage from '@/views/MainPage.vue';
import CambioPass from '@/views/CambioPass.vue';
import MenuMobile from '@/views/MenuMobile.vue';
import DemandaVirtual from '@/views/DemandaVirtual.vue';

const routes = [
  {
    path:'/',
    name:'Raiz',
    component:LoadingPage,
  },
  {
    path:'/:cliente/loading',
    name:'Loading',
    component:LoadingPage,
  },
  {
    path:'/:cliente/cambio-pass',
    name:'CambioPass',
    component:CambioPass
  },
  {
    path: '/:cliente/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/:cliente',
    name: 'Portal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:Home, //import(/* webpackChunkName: "about" */ '../views/About.vue')
    children:[
      {
        path: '',
        name: 'Menu',
        component: MenuMobile,
      },
      {
        path:'mis-turnos',
        name:'Mis Turnos',
        component:MisTurnos,
      },
      {
        path:'guardia-virtual',
        name:'Guardia Virtual',
        component:DemandaVirtual,
      },
      {
        path:'nuevo-turno',
        name:'Nuevo Turno',
        component:NuevoTurno,
      },
      {
        path:'mis-datos',
        name:'Mis Datos',
        component:MisDatos,
      },
      {
        path:'resultados',
        name:'Resultados',
        component:Resultados,
      },
      {
        path:'recetas',
        name:'Recetas',
        component:Recetas,
      }
    ]
  },
  {
    path:'/404',
    name:'404',
    component:NotFoundComp,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to, from, next)=>{

  //return to.name !== 'CambioPass' ? next({name:'CambioPass',params:to.params}) : next()
  const token = to.query.token;
  if(token){
    console.log(token)
    localStorage.setItem('tempToken',token as string);
  }

  if(to.name === 'Raiz'){
    const cliente = localStorage.getItem('cliente');
    if(cliente){
      return next({name:'Loading',params:{cliente}});
    }
    return next({name:'404'});
  }

  if(to.name === '404') return next();
  

  if(!store.state.configuracionPortal){
    if(to.name === 'Loading') return next();

    return next({name:'Loading',params:to.params})
  }


  if(!store.state.isAutenticated){
    if(to.name === 'Login') return next();
    
    return next({name:'Login', params:to.params});
  }

  next();

});

export default router
