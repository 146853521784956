
import { defineComponent,ref, onBeforeMount, computed, watchEffect } from "vue";
import { useStore } from 'vuex';
import axios from '@/plugins/axios';
import { jsPDF } from 'jspdf';
import { imprimirRC }from '@/hooks/useImpresion'
import useLogo from '@/hooks/useLogo';


import RecetasCard from '@/components/RecetasCard.vue'
import SolicitudesPendientesCard from '@/components/SolicitudesPendientes.vue'
import  SolicitudReceta  from "@/components/SolicitudReceta.vue"


import { useToast } from "primevue/usetoast"

export default defineComponent({
    components:{
        RecetasCard,
        SolicitudReceta,
        SolicitudesPendientesCard,
    },
    setup(){
        const store = useStore();
        const logo = useLogo();
        const toast = useToast();

        const solicitudRecetaForm = ref(false);
        const recetasList = ref([]);
        
        const contenedorResultados = ref<HTMLDivElement>();
        const itemsPorPagina = ref(10);
        const paginaActual = ref(1);
        const cantidadResultados = ref(0);

        const CantidadPedidos = ref(0);
        const SolicitudesActivasForm = ref(false);
        const SolicitudesActivas = ref();

        
        const totalPaginas = computed(() => {
            return Math.ceil(cantidadResultados.value / itemsPorPagina.value);
        });

        const nuevaSolicitudReceta = () => {
            solicitudRecetaForm.value = true;
        }
        
        const solicitudRecetaDuplicada = async (receta:any) => {
            try {
                const {data} = await axios.get(`/historia-clinica-get-registros/${receta._id}`)
                
                await axios.post('/tareas-pendientes',{
                    datos:{
                        receta:data,
                        paciente:store.state.pacienteLogueado.id,
                    }
                })

                toast.add({
                    severity:'success',
                    summary:'Solicitud duplicada',
                    detail:'Se ha duplicado la solicitud de receta correctamente.',
                    life:3000
                })
                
            } catch (error) {
                console.log(error);
            }
        }
        

        const handleSolicitudReceta = (receta?:any) =>{
            
            receta ? solicitudRecetaDuplicada(receta) : nuevaSolicitudReceta();      
            
        }

        const handleSolicitudRecetaClose = (value:{pendientes:number, tareaCreada:any}) =>{
            if(value?.pendientes){
                CantidadPedidos.value = value.pendientes;

                SolicitudesActivas.value = [...SolicitudesActivas.value, value.tareaCreada];
            }

            solicitudRecetaForm.value = false;
        }

        const handleClickMensajeSolicitudes = () =>{
            SolicitudesActivasForm.value = true;
        }

        const handleCancelarSolicitud = (solicitud:any) =>{
            SolicitudesActivas.value = SolicitudesActivas.value.filter((item:any) => item.id !== solicitud.id);
            
            CantidadPedidos.value = SolicitudesActivas.value.length;

            if(!CantidadPedidos.value){
                SolicitudesActivasForm.value = false;
            }
        }


        const descargarRecetas = async (receta:any)=>{
            if(receta.datos?.recetaElectronica) {
                const downloadLink = axios.defaults.baseURL + '/receta/get-receta/'+receta._id                                         
                window.open(downloadLink);
                return 
            }
            const pdfjs =  new jsPDF({
                format: 'a5',
                unit:'pt',
                hotfixes:["px_scaling"]
            });

            try {
                
                const {data:{firma,pie}} = await axios.get(`/medicos/get-firma-pie/${receta.medico.id}`);

                if(!firma){
                    toast.add({
                        severity:'error',
                        summary:'Atención',
                        detail:'Esta receta no posee firma, antes de poder descargarla debe ser firmada por el profesional.',
                    })
                    return;
                }


                


                const result = await axios.get(`/historia-clinica-get-registros/${receta._id}`)
                if(result.data.datos.datosPaciente){
                    result.data.datos.datosPaciente.condiciones = await getCondicionesPacientes(result.data.datos.datosPaciente)
                }

                result.data.datos.diagnostico.codifica_nombre = await getDiagnosticoReceta(result.data.datos.diagnostico.id);

                await imprimirRC(result.data,pdfjs,firma,pie,logo);


                pdfjs.output('dataurlnewwindow',{filename:`Receta-${result?.data?.datos?.datosPaciente?.nombre || 'Portal'}.pdf`});
                pdfjs.save(`Receta-${result?.data?.datos?.datosPaciente?.nombre || 'Portal'}.pdf`);

            } catch (error) {
                console.log(error);
            }

        }

        const getDiagnosticoReceta = async (iddiagnostico:number) => {
            try {
                const {data} = await axios.get('/turnos-diagnosticos', {
                    params:{
                        filters:{
                            id:iddiagnostico
                        }
                    }
                })
                const [ diagnostico ] = data.rows
                return diagnostico.codifica_nombre || false;
            } catch (error) {
                console.log(error);
            }
        }

        const getCondicionesPacientes = async ({numerodocumento}:any) => {
            try {
                let condiciones : Record<string,any>  = {}
                const { data:afiliado } = await axios.get('/cobertura-padron/get-all', {
                    params:{
                        nroAfiliado:numerodocumento,
                        verificarPadron: true
                    }
                })

                condiciones = {
                    'Cronico':afiliado[0]?.cronicidad === 'Si',
                    'Discapacidad':afiliado[0]?.incapacidad === 'Si',
                    'PMI':afiliado[0]?.pmi === 'Si',
                }

                Object.keys(condiciones).forEach((key) => {
                    if(!condiciones[key]) delete condiciones[key]
                })

                return Object.keys(condiciones).join(', ') || '-';
                
            } catch (error) {
                console.log(error);
                return '';   
            }
        }


        const obtenerRecetasPaciente = async () =>{
            try {
                const query = encodeURIComponent(JSON.stringify({$or:[{tipo:'RC'},{$and:[{tipo:'OR'},{'datos.tipoSolicitud':'medicamento'}]}]}));
                
                const {data} = await axios.get(`/historia-clinica-get-registros/${store.state.pacienteLogueado.id}/${paginaActual.value}/${itemsPorPagina.value}?query=${query}`);
                
                recetasList.value = paginaActual.value === 1 ? data.registros : [...recetasList.value, ...data.registros];
                
                if(paginaActual.value === 1) cantidadResultados.value = data.count;
                
            } catch (error) {
                console.log(error);
            }
        }

        const obtenerCantidadSolicitudes = async () =>{
            try {
                
                const {data} = await axios.get(`/tareas-pendientes-cantidad/${store.state.pacienteLogueado.id}`);
                
                CantidadPedidos.value = data.length;

                SolicitudesActivas.value = data;
                
            } catch (error) {
                console.log(error);
            }
        }


        onBeforeMount(() => {
            obtenerRecetasPaciente();
            obtenerCantidadSolicitudes();

        })

        watchEffect(() => {
            
            if(contenedorResultados.value){
                contenedorResultados.value.removeEventListener('scroll', eventoDeScroll);
                contenedorResultados.value.addEventListener('scroll', eventoDeScroll);
            }
        })

        const eventoDeScroll = (e:any) => {
            const { target:{scrollTop, offsetHeight, scrollHeight} } = e;
            
            if(scrollTop + offsetHeight >= scrollHeight){
               
                paginaActual.value += 1;
                if(totalPaginas.value >= paginaActual.value){
                    
                    obtenerRecetasPaciente();
                }
                
                
            }
        }




        return {
            contenedorResultados,
            recetasList,
            CantidadPedidos,
            SolicitudesActivas,
            descargarRecetas,
            solicitudRecetaForm,
            SolicitudesActivasForm,
            handleSolicitudReceta,
            handleSolicitudRecetaClose,
            handleClickMensajeSolicitudes,
            handleCancelarSolicitud,
        }

    }

})
