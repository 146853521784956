
import InputNumber from 'primevue/inputnumber';
import { defineComponent, ref, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router'


import { useToast } from 'primevue/usetoast';
import { useConfirm } from "primevue/useconfirm";
import axios from '@/plugins/axios';


export default defineComponent({
    components:{
        InputNumber
    },
    setup: () => {
        const store = useStore()
        const route = useRoute();
        const router = useRouter();
        const paciente = store.state.pacienteLogueado
        const logo = 'https://previews.123rf.com/images/karom/karom1610/karom161000146/67581679-logotipo-de-m%C3%A9dicos-logotipo-centro-m%C3%A9dico-logotipo-de-la-salud-logotipo-m%C3%A9dico-logotipo-de-medicina.jpg'
        const toast = useToast();
        const dialog = useConfirm();
        const estaEditando = ref(false)
        const editarPass = ref(false);
    
        
        const nombre = ref(paciente.nombre)
        const email = ref(paciente.email)
        const celular = ref(paciente.celular || null)
        const direccion = ref(paciente.calle)
        const numero = ref(paciente.numero)
        const codpais = ref(paciente?.codpais?.toString())
        const codarea = ref(paciente?.codarea?.toString())
        const piso = ref(paciente.piso)
        const depto = ref(paciente.depto)
        const localidad = ref(paciente.localidad)
        const provincia = ref(paciente.provincia)
        const pais = ref(paciente.pais)

        const edit = ref({email:email,celular:celular,direccion:direccion, numero:numero,codpais:codpais, codarea:codarea , piso:piso, depto:depto, localidad:localidad, provincia:provincia, pais:pais});
        

        const validateForm = () => {
            let error = false
            if(!celular.value){
                error = true
                dialog.require({
                    header:'Atención',
                    message:'Complete por favor su número de contacto.',
                    acceptLabel:'Aceptar',
                    rejectLabel:'',
                    rejectClass:'tw-hidden'
                })
            }
            return error
        }

        const handleSaveButton = () => {
            estaEditando.value ? savePaciente() : estaEditando.value = true
        }

        const handleCancelButton = () => {
            if(estaEditando.value){
                nombre.value = paciente.nombre
                email.value = paciente.email
                celular.value = paciente.celular || null
                direccion.value = paciente.calle
                numero.value = paciente.numero
                codpais.value = paciente?.codpais?.toString()
                codarea.value = paciente?.codarea?.toString()
                piso.value = paciente.piso
                depto.value = paciente.depto
                localidad.value = paciente.localidad
                provincia.value = paciente.provincia
                pais.value = paciente.pais
                estaEditando.value = false
            }else{
                router.push(`/${route.params.cliente}/`)
            }
        }

        const savePaciente = () =>{
            if(validateForm()){
                return
            }
            if(!email.value){
                dialog.require({
                    header:'Atención',
                    message:`Complete por favor su email de contacto. \nEs necesario para poder recuperar su contraseña de ingreso a futuro`.trim(),
                    acceptLabel:'Aceptar',
                    rejectLabel:'Cancelar',
                    rejectClass:'tw-hidden',
                    accept:() => confirmSavePaciente()
                })
                return;
            }
            confirmSavePaciente()
        }

        const confirmSavePaciente = async () => {
              try {
                const result = await axios.put(`pacientes/update/${paciente.id}` , {
                    email: email.value,
                    celular: celular.value == '-' ? '' : celular.value,
                    codarea: codarea.value,
                })
                store.state.pacienteLogueado = { ...store.state.pacienteLogueado, ...result.data.paciente}
                toast.add({severity:'success',summary:'Exito',detail:'Se actualizaron los datos correctamente',life:1500})
                estaEditando.value = false;
            } catch (error) {
                toast.add({severity:'error',summary:'Error',detail:'Error al actualizar los datos',life:1500});
                console.log(error);
            }
            
        }

        const handleEditarPassClick = (bool:boolean) =>{
            editarPass.value = bool;
        }


        onMounted(() => {
                
                const { q } = route.query;

                if(!q) return;

                estaEditando.value = true;


                nextTick(() => {
                    document.getElementById(q === 'email' ? 'inputEmail' : 'inputTelefono')?.focus()
                })
        })


        return {
            logo,
            edit,
            editarPass,
            nombre,
            email,
            celular,
            direccion,
            numero,
            codpais,
            codarea,
            piso,
            depto,
            localidad,
            provincia,
            pais,
            handleEditarPassClick,
            handleSaveButton,
            estaEditando,
            handleCancelButton,
        }
    }
})
