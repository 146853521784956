
import {defineComponent, ref} from 'vue'
import useBackground from '@/hooks/useBackground'
import axios from '@/plugins/axios'
import { useRoute, useRouter } from 'vue-router'


export default defineComponent({
    setup(){
        const BackgroundImage = useBackground()
        const password = ref('');
        const router = useRouter();
        const route = useRoute();
        const isLoad = ref(false);
        const isOut = ref(false);
        const passwordRepeat = ref('');
        const error = ref();

        const validarContraseñas = () => {
            let valida = true;

            valida = valida && password.value === passwordRepeat.value;

            if(!valida){
                error.value = 'las contraseñas deben ser iguales';
                passwordRepeat.value = '';
                return valida;
            }

            valida = valida && !(password.value.length < 8);

            if(!valida) {
                error.value = 'la contraseña debe tener mas de 8 caracteres';
                password.value = '';
                passwordRepeat.value = '';
                return valida
            }

            return valida;
        }


        const handleClick = () =>{

            if(validarContraseñas()){
                isLoad.value = true;
                axios.post('pacientes/cambio-password', {password:password.value})
                .then(()=>{
                    isOut.value = true;
                    setTimeout(() => {
                        const { cliente } = route.params
                        router.push(`/${cliente}/`);
                    },500)
                })
                .catch(console.log)
                .finally(()=>isLoad.value =false)
            }

        }


        return {
            password,
            passwordRepeat,
            error,
            isLoad,
            isOut,
            handleClick,
            BackgroundImage
            }
    }
})
